import React from 'react';
import { Link, graphql } from 'gatsby';
import { Layout } from '@/components/Layout';
import { Card, styled, CardContent, Typography, Box, Container, Grid } from '@mui/material';
import Helmet from 'react-helmet';

const PostCard = styled(Card)`
  max-width: 400px;
`;

const Blog = ({ data }) => {
  const { edges: posts } = data.allMdx;
  return (
    <Layout>
      <Container sx={{ minHeight: '70vh', pt: 7, pb: 7 }} maxWidth="md">
        <Helmet title="PollPilot | Blog" />
        <Grid container sx={{ gap: 2 }}>
          {posts
            ?.sort((a, b) =>
              (b.node.frontmatter?.date || '').localeCompare(a.node.frontmatter?.date),
            )
            .map(({ node: post }) => (
              <PostCard key={post.id} variant="outlined">
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {post.frontmatter.date}
                  </Typography>
                  <Typography variant="h2" component={Link} to={post.frontmatter.slug || post.slug}>
                    {post.frontmatter.title}
                  </Typography>
                  <Box mt={2} />
                  <Typography variant="subtitle1">{post.excerpt}</Typography>
                </CardContent>
              </PostCard>
            ))}
        </Grid>
      </Container>
    </Layout>
  );
};
export const pageQuery = graphql`
  query blogIndex {
    allMdx {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            slug
            date
          }
          slug
        }
      }
    }
  }
`;
export default Blog;
